<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="732"
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.manualOrders.TYPE_TARIF_QUEST }}
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mx-6"></v-divider>
      <v-card-text>
        <v-container class="pa-0">
          <v-radio-group v-model="radioServiceType">
            <v-radio v-for="item in form.items.serviceType" :key="item.value"  :value="item.value">
              <template class="flex-column" v-slot:label>
                <div class="w-100">
                  <span class="d-block">{{item.label}}</span>
                  <span class="caption">{{item.value === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : item.value}}</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <span v-if="form.items.serviceType.length === 0">Tidak ada jenis tarif tersedia</span>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-2" justify="end" no-gutters>
          <v-col cols="12" sm="3">
            <v-btn
              color="primary"
              block
              outlined
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" class="ml-0 ml-sm-2 mt-2 mt-sm-0">
            <v-btn
              v-if="form.items.serviceType.length > 0"
              color="primary"
              block
              :disabled="!radioServiceType"
              @click="setServiceType"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      radioServiceType: '',
    };
  },
  methods: {
    setServiceType() {
      this.form.serviceType = this.radioServiceType;
      this.dialog = false;
      this.$parent.$parent.$emit('resetValueData', 'serviceType');
      this.$parent.$parent.$emit('getTransportType');
      this.$parent.$parent.$emit('getOrigin');
      // REMOVE BORDER AND MESSAGE ERROR SERVICE TYPE
      this.$parent.$parent.$refs.cardServiceType.$el.classList.remove('v-card-error');
      this.$parent.$parent.$refs.errorServiceType.innerHTML = '';
      this.$parent.$parent.$refs.errorServiceType.classList.remove('v-messages', 'error--text');
    },
  },
};
</script>
