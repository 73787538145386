<template>
  <v-dialog
    v-model="dialog"
    max-width="612"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.manualOrders.VEHICLE_TITLE }}
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row v-if="form.items.itemsVehicle"  justify="center">
            <v-col v-for="vehicle in form.items.itemsVehicle" :key="vehicle.transportTypeId" md="4" cols="12" class="pa-0 text-center">
              <v-container>
                <v-card
                  :ref="vehicle.transportTypeId"
                  class="mx-auto"
                  max-width="200"
                  min-width="180"
                  outlined
                  :class="vehicle.transportTypeId === selectedVehicleId ? 'v-card-border-blue' : null"
                  @click="clickVehicle(vehicle.transportTypeId)"
                >
                  <v-list-item class="pa-0" three-line>
                    <v-list-item-content class="pa-0">
                      <div style="min-height:100px" class="overline mb-4 d-flex justify-center">
                        <v-icon
                          v-if="!vehicle.imageUrl"
                          x-large
                          class="w3"
                        >
                          mdi-truck-outline
                        </v-icon>
                        <v-img
                          v-else
                          class="text-center"
                          lazy-src="http://placehold.it/200"
                          max-height="100"
                          max-width="150"
                          contain
                          :src="vehicle.imageUrl"
                        ></v-img>
                      </div>
                      <v-list-item-title class="caption mb-1">
                      </v-list-item-title>
                      <div class="mb-2">
                        <v-btn
                          :ref="'button_vehicle'+vehicle.transportTypeId"
                          rounded
                          min-width="140"
                          max-width="180"
                          class="caption"
                          small
                          :class="vehicle.transportTypeId === selectedVehicleId ? 'primary' : null"
                        >
                          {{vehicle.name}}
                        </v-btn>
                      </div>
                      <v-list-item-subtitle
                        class="d-flex justify-center"
                        :class="vehicle.transportTypeId === selectedVehicleId ? 'blue lighten-5' : 'grey lighten-5'"
                      >
                        <p class="caption"> {{ vehicle.length }} &#215; {{ vehicle.width }} &#215; {{ vehicle.height }} <br/> Maks {{ vehicle.maxWeight }} ton.</p>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row
          v-if="form.items.itemsVehicle && form.items.itemsVehicle.length > 0"
          class="mb-2"
          justify="end"
        >
          <v-col cols="12" sm="4">
            <v-btn
              block
              color="primary"
              outlined
              @click="dialog = false"
            >
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              block
              color="primary"
              :disabled="!selectedVehicleId"
              @click="setVehicle"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
        <div class="w-100" v-else >
          <p class="headline mt-2 text-center">Tidak ada kendaraan tersedia</p>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      selectedVehicleId: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.selectedVehicleId = 0;
      }
    },
  },
  methods: {
    clickVehicle(val) {
      this.selectedVehicleId = val;
    },
    setVehicle() {
      this.dialog = false;
      this.form.transportTypeId = this.selectedVehicleId;

      this.$parent.$parent.$emit('resetValueData', 'vehicle');
      this.$parent.$parent.$emit('getOrigin');
      // REMOVE BORDER AND MESSAGE ERROR VEHICLE
      this.$parent.$parent.$refs.cardVehicleType.$el.classList.remove('v-card-error');
      this.$parent.$parent.$refs.errorVehicle.innerHTML = '';
      this.$parent.$parent.$refs.errorVehicle.classList.remove('v-messages', 'error--text');
      this.selectedVehicleId = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-card-border-blue {
    border-color: rgb(52, 52, 185);
  }
  ::v-deep .v-card {
    overflow: hidden;
  }
</style>
