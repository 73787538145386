<template>
  <v-dialog
    v-model="dialog"
    max-width="732"
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.manualOrders.ORIGIN_TITLE }}
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-form ref="itemLocOrigin" lazy-validation @submit.prevent="setLocOrigin">
        <v-card-text>
          <v-container class="pa-0">
            <v-autocomplete
              v-if="isBackhauling"
              v-model="originLocationId"
              class="body-2 mt-5"
              outlined
              color="primary"
              prepend-inner-icon="mdi-map-marker"
              :placeholder=$_strings.manualOrders.ORIGIN_LOC
              :items="form.items.itemsOriginBackhauling"
            >
            </v-autocomplete>
            <common-auto-complete-items
              v-else
              :optional="{
                serviceType: form.serviceType,
                transportTypeId: form.transportTypeId,
                mouId: $route.query.mouId
              }"
              item-value="originLocationId"
              item-text="originLocationName"
              type="mouOriginRoute"
              searchName="originName"
              outlined
              class="body-2 mt-5"
              prepend-inner-icon="mdi-map-marker"
              v-model="originLocationId"
              hide-details
              :placeholder=$_strings.manualOrders.ORIGIN_LOC
              :filter="filter"
              :items="form.items.itemsOrigin"
              @updateItems="updateItems"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                outlined
                block
                class="mb-2 mr-2"
                @click="dialog = false"
              >
                {{$_strings.common.BACK}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                block
                type="submit"
                :disabled="!originLocationId"
              >
                {{$_strings.common.SAVE}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import { filterDuplicateValues } from '@/helper/commonHelpers';

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    isBackhauling: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dialog: false,
      originLocationId: '',
      oldFilter: {
        page: 0,
        totalData: 0,
      },
    };
  },
  methods: {
    updateItems(items) {
      const newLocOrigin = [...this.form.items.itemsOrigin];
      items.forEach((route) => {
        if (!newLocOrigin.some((n) => n.id === route.id)) {
          newLocOrigin.push({
            ...route,
            id: route.id,
            text: route.originLocationName,
            value: route.originLocationId,
            originLocationCityId: route.originLocationCityId,
            originLocationCityName: route.originLocationCityName,
            originLocationAddress: route.originLocationAddress,
          });
        }
      });
      this.form.items.itemsOrigin = newLocOrigin;
    },
    setLocOrigin() {
      this.dialog = false;
      if (this.isBackhauling) {
        this.form.backhauling.originLocationId = this.originLocationId;
        this.$parent.$parent.$emit('resetValueData', 'locOriginBackhauling');
        this.$parent.$parent.$emit('getDestination', this.originLocationId);
      } else {
        this.form.originLocationId = this.originLocationId;
        this.$parent.$parent.$emit('resetValueData', 'locOrigin');
        this.$parent.$parent.$emit('getDestination');
      }
      this.originLocationId = '';
      // REMOVE BORDER AND MESSAGE ERROR ORIGN
      this.$parent.$parent.$refs.cardLocOrigin.$el.classList.remove('v-card-error');
      this.$parent.$parent.$refs.errorLocOrigin.innerHTML = '';
      this.$parent.$parent.$refs.errorLocOrigin.classList.remove('v-messages', 'error--text');
    },
  },
};
</script>
