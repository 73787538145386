<template>
  <v-form  ref="createOrder" lazy-validation @submit.prevent="createOrder">
    <v-container fluid>
      <!-- DATE AND TIME -->
      <v-row>
        <v-col>
          <v-card>
            <v-container fluid>
              <v-row class="mt-2">
                <v-col cols="12" class="pt-0">
                  <h4>{{ $_strings.manualOrders.PICKUP_DATE }}</h4>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-0">
                  <v-menu
                    ref="menu_date"
                    v-model="menu_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="form.tools.pickupDate && dateFormat(form.tools.pickupDate) || null"
                        placeholder="Tanggal"
                        outlined
                        :rules="rules.dateRules"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        prepend-inner-icon="mdi-calendar-blank"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.tools.pickupDate"
                      :min="minBackDate"
                      @change="() => {
                        $emit('getMultishipper')
                        handlePickupDateChange()
                        $refs.time.focus()
                        $refs.time.reset()
                      }"
                      @input="menu_date = false"
                      locale="id"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-0">
                  <v-text-field
                    ref="time"
                    v-mask="{'alias': 'time'}"
                    outlined
                    label="Jam"
                    prepend-inner-icon="mdi-clock"
                    v-model="form.tools.pickupTime"
                    @input="handlePickupDateChange"
                    :rules="rules.time"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <!-- CONFIRM -->
      <v-row v-if="showCardConfirm">
        <v-col>
          <v-card ref="cardConfirm">
            <v-container fluid>
              <v-checkbox
                label="KONFIRMASI 3PL DAN DRIVER MANUAL"
                class="font-weight-bold"
                hide-details
                v-model="form.isManualConfirmed"
              ></v-checkbox>
              <v-checkbox
                label="UPDATE STATUS DRIVER MANUAL"
                class="font-weight-bold"
                v-model="form.isManualStatusUpdated"
              ></v-checkbox>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <!-- SERVICE TYPE -->
      <v-row>
        <v-col>
          <v-card ref="cardServiceType">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.manualOrders.TYPE_OF_TARIFF }}</h4>
                  <span class="text-silverChalice">
                    {{form.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : form.serviceType? form.serviceType : 'Pilih jenis tarif'}}
                  </span>
                </v-col>
                <v-col class="text-right">
                  <v-tooltip v-if="loaders.isLoadingGetServiceType" top v-model="showTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        indeterminate
                        color="primary"
                        size="25"
                      ></v-progress-circular>
                    </template>
                    <span>{{$_strings.manualOrders.LOAD_MESSAGE.SERVICE_TYPE}}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="errors.isErrorGetServiceType"
                    color="primary"
                    class="mt-3"
                    @click="$emit('getServiceType')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-icon
                    v-else-if="form.items.serviceType.length > 1"
                    color="primary"
                    class="mt-3"
                    @click="$refs.dialogServiceType.dialog = true"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span ref="errorServiceType" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- VEHICLE / TRANSPORTER -->
      <v-row>
        <v-col :class="form.serviceType === 'LCL' ? 'pa-2' : null">
          <v-card v-if="form.serviceType !== 'LCL'" ref="cardVehicleType" min-height="120">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.manualOrders.VEHICLE }}</h4>
                  <div v-if="!form.transportTypeId || !form.items.itemsVehicle.length">
                    <span class="text-silverChalice">{{ $_strings.manualOrders.VEHICLE_TYPE }}</span>
                  </div>
                  <div v-else>
                    <span class="text-silverChalice">
                      {{ form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).name }}
                    </span>
                    <p class="text-silverChalice">
                      {{ form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).length }}
                      &#215; {{ form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).width }}
                      &#215; {{ form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).height }} | Maks {{ form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).maxWeight }} ton</p>
                  </div>
                </v-col>
                <v-col class="d-flex justify-end" v-if="form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId)">
                  <v-img
                    contain
                    class="text-center"
                    lazy-src="http://placehold.it/200"
                    max-height="80"
                    max-width="120"
                    :src="form.items.itemsVehicle.find((transporter) => transporter.transportTypeId === form.transportTypeId).imageUrl"
                  ></v-img>
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-tooltip v-if="loaders.isLoadingGetTransportType" top v-model="showTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        indeterminate
                        color="primary"
                        size="25"
                      ></v-progress-circular>
                    </template>
                    <span>{{$_strings.manualOrders.LOAD_MESSAGE.TRANSPORT_TYPE}}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="errors.isErrorGetTransportType"
                    color="primary"
                    class="mt-3"
                    @click="$emit('getTransportType')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-icon
                    v-else-if="form.items.itemsVehicle.length > 1"
                    color="primary"
                    class="mt-3"
                    @click="$refs.dialogVehicle.dialog = true"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span v-if="form.serviceType !== 'LCL'" ref="errorVehicle" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- ORIGIN -->
      <v-row>
        <v-col>
          <v-card ref="cardLocOrigin">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.manualOrders.ORIGIN_LOC }}</h4>
                  <div class="d-flex">
                    <div>
                      <v-icon class="mr-2 ml-4 icon-origin">
                        mdi-map-marker
                      </v-icon>
                    </div>
                    <div class="d-inline">
                      <span>
                        {{ form.originLocationId && form.items.itemsOrigin.length && form.items.itemsOrigin.find((origin) => origin.value === form.originLocationId).text || $_strings.manualOrders.ORIGIN_TITLE }}
                      </span>
                      <br/>
                      <span class="text-silverChalice">
                        {{form.originLocationId && form.items.itemsOrigin.length && form.items.itemsOrigin.find((origin) => origin.value === form.originLocationId).originLocationAddress || '-'}}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-tooltip v-if="loaders.isLoadingGetOrigin" top v-model="showTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        indeterminate
                        color="primary"
                        size="25"
                      ></v-progress-circular>
                    </template>
                    <span>{{$_strings.manualOrders.LOAD_MESSAGE.LOCATION}}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="errors.isErrorGetOrigin"
                    color="primary"
                    class="mt-3"
                    @click="$emit('getOrigin')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-icon v-else-if="form.items.itemsOrigin.length" color="primary" class="mt-3" @click="$refs.dialogOrigin.dialog = true" >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea
                    :placeholder=$_strings.manualOrders.ADD_INFO
                    no-resize
                    outlined
                    v-model="form.originNote"
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.manualOrders.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column">
                <v-col class="pt-0">
                  <span class="grey--text body">{{ $_strings.manualOrders.ORIGIN_CONTACT }}</span>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    :placeholder=$_strings.manualOrders.ORIGIN_NAME
                    class="body-2"
                    outlined
                    v-model="form.originSenderName"
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                  <phone-number-format
                    :placeholder=$_strings.manualOrders.ORIGIN_PHONE
                    bindClass="body-2"
                    outlined
                    v-model="form.originSenderPhone"
                    prepend-inner-icon="mdi-phone"
                    v-bind="$attrs"
                    :required="false"
                  ></phone-number-format>
                </v-col>
                <v-col class="pt-0 pb-0 mb-4">
                  <Multipick
                    v-if="form.serviceType !== 'LCL' && form.items.itemsMultipick.length"
                    :shipmentsDetail="form.shipmentsDetail"
                    :itemsMultipick="form.items.itemsMultipick"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span ref="errorLocOrigin" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- DESTINATION -->
      <v-row>
        <v-col>
          <v-card ref="cardLocDest">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.manualOrders.DESTINATION_TITLE }}</h4>
                  <div class="d-flex">
                    <div>
                      <v-icon color="yellow accent-4" class="mr-2 ml-4">
                        mdi-map-marker
                      </v-icon>
                    </div>
                    <div class="d-inline">
                      <span>
                        {{ form.destinationLocationId && form.items.itemsDestination.length && form.items.itemsDestination.find((destination) => destination.value === form.destinationLocationId).text || $_strings.manualOrders.ORIGIN_TITLE }}
                      </span>
                      <br/>
                      <span class="text-silverChalice">{{
                        form.destinationLocationId && form.items.itemsDestination.length && form.items.itemsDestination.find((destination) => destination.value === form.destinationLocationId).destinationLocationAddress || '-'}}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="auto" class="text-right">
                  <v-tooltip v-if="loaders.isLoadingGetDestination" top v-model="showTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        indeterminate
                        color="primary"
                        size="25"
                      ></v-progress-circular>
                    </template>
                    <span>{{$_strings.manualOrders.LOAD_MESSAGE.LOCATION}}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="errors.isErrorGetDestination"
                    color="primary"
                    class="mt-3"
                    @click="$emit('getDestination')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-icon
                    v-else-if="form.items.itemsDestination.length"
                    color="primary"
                    class="mt-3"
                    @click="$refs.dialogDestination.dialog = true"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea
                    :placeholder=$_strings.manualOrders.ADD_INFO
                    no-resize
                    v-model="form.destinationNote"
                    outlined
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.manualOrders.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column">
                <v-col class="pt-0">
                  <span class="grey--text body">{{ $_strings.manualOrders.DESTINATION_CONTACT }}</span>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    :placeholder=$_strings.manualOrders.DESTINATION_NAME
                    class="body-2"
                    v-model="form.destinationReceiverName"
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                  <phone-number-format
                    :placeholder=$_strings.manualOrders.DESTINATION_PHONE
                    bindClass="body-2"
                    outlined
                    v-model="form.destinationReceiverPhone"
                    prepend-inner-icon="mdi-phone"
                    :required="false"
                  ></phone-number-format>
                </v-col>
                <v-col class="pt-0 pb-0 mb-4">
                  <Multidrop
                    v-if="form.serviceType !== 'LCL' && form.items.itemsMultidrop.length"
                    :shipmentsDetail="form.shipmentsDetail"
                    :itemsMultidrop="form.items.itemsMultidrop"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span ref="errorLocDest" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- GOODS -->
      <Goods
        :form="form"
        :itemsCategory="form.items.itemsCategory"
        :itemsSubCategory="form.items.itemsSubCategory"
        :filterCategory="filters.category"
        :filterSubCategory="subCategoryFilter"
        @updateSubCategoryItems="updateSubCategoryItems"
        @resetSubCategoryItems="resetSubCategoryItems"
        @updateCategoryItems="(items) => $emit('updateCategoryItems', items)"
      />

      <!-- KUBIKASI / TONASE / KARTON -->
      <section>
        <v-row v-if="form.serviceType === 'LCL'">
          <v-col>
            <v-card>
              <v-container fluid>
                <v-row class="flex-md-row flex-column mt-2">
                  <v-col cols="auto" class="pt-0 mr-2">
                    <h4 class="mb-2">{{ $_strings.manualOrders.KUBIKASI }}
                      <span class="red--text">*</span>
                      <!-- <span class="red--text" v-if="!form.isMultiShipper">*</span> -->
                    </h4>
                    <!-- @change="changeCubicOrTonase" -->
                    <v-text-field
                      suffix="m3"
                      class="body-2 mb-2"
                      outlined
                      v-mask="{'alias': 'decimal',rightAlign: false}"
                      :rules="rules.cubic"
                      v-model="form.cubic"
                    ></v-text-field>
                    <!-- :rules="form.isMultiShipper ? [] : rules.cubic" -->
                  </v-col>
                  <v-col cols="auto" class="pt-0 mr-2">
                    <h4 class="mb-2">{{ $_strings.manualOrders.TONASE }}
                      <span class="red--text">*</span>
                    </h4>
                    <!-- @change="changeCubicOrTonase" -->
                    <v-text-field
                      suffix="Kg"
                      class="body-2 mb-2"
                      outlined
                      v-mask="{'alias': 'decimal',rightAlign: false}"
                      :rules="rules.tonase"
                      v-model="form.tonase"
                    ></v-text-field>
                    <!-- :rules="form.isMultiShipper ? [] : rules.tonase" -->
                  </v-col>
                  <v-col v-if="form.isMultiShipper" cols="auto" class="pt-0 mr-2">
                    <h4 class="mb-2">{{ $_strings.manualOrders.KARTON }} <span class="red--text">*</span></h4>
                    <!-- :disabled="!!(form.tonase && form.cubic && !form.carton)" -->
                    <v-text-field
                      suffix="Ktn"
                      class="body-2 mb-2"
                      outlined
                      v-mask="{'alias': 'decimal',rightAlign: false}"
                      :rules="rules.carton"
                      v-model="form.carton"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <!-- NOTES -->
      <v-row>
        <v-col>
          <v-card>
            <v-container fluid>
              <v-row class="d-flex flex-column mt-2">
                <v-col class="pt-0">
                  <h4>{{ $_strings.manualOrders.ADD_NOTE }}</h4>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-textarea
                    :placeholder="$_strings.manualOrders.ADD_INFO"
                    no-resize
                    v-model="form.additionalNote"
                    outlined
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.manualOrders.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <!-- MULTISHIPPER -->
      <!--  -->
      <v-row v-if="form.isMultiShipper && form.serviceType === 'LCL'">
        <v-col>
          <v-card>
            <v-container fluid>
              <v-row class="d-flex flex-column mt-2">
                <v-col class="pt-0">
                  <h4>{{ $_strings.manualOrders.MULTISHIPPER }}</h4>
                </v-col>
                <v-col class="d-flex pb-0">
                  <div class="w-100">{{ $_strings.manualOrders.COMBINE_SHIPPER }}</div>
                  <div>
                    <v-checkbox
                      class="pa-0 ma-0"
                      v-model="form.isShipperMergeShipment"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col class="pt-0 pb-0 text-center">
                  <v-icon
                    v-if="errors.isErrorGetMultishipper"
                    color="primary"
                    size="50"
                    @click="$emit('getMultishipper')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-select
                    v-else
                    :loading="loaders.isLoadingGetMultishipper"
                    :items="form.items.itemsNoOrders"
                    :placeholder=$_strings.manualOrders.ORDER_NO
                    class="body-2"
                    outlined
                    :disabled="!form.isShipperMergeShipment"
                    :rules="form.isShipperMergeShipment ? rules.reffShipperMergeShipmentId : []"
                    v-model="form.reffShipperMergeShipmentId"
                  ></v-select>
                </v-col>
                <v-col class="d-flex pb-0 pt-0">
                  <div class="w-100">{{ $_strings.manualOrders.FULL_LOAD }}</div>
                  <div>
                    <v-checkbox
                      class="pa-0 ma-0"
                      v-model="form.isShipperFullLoad">
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <DialogServiceType
        ref="dialogServiceType"
        v-bind="$props"
      />
      <DialogVehicle
        ref="dialogVehicle"
        v-bind="$props"
      />
      <DialogOrigin
        ref="dialogOrigin"
        :filter="filters.originLocation"
        :isLoading="loaders.isLoadingGetMoreOrigin"
        v-bind="$props"
      />
      <DialogDestination
        ref="dialogDestination"
        :filter="filters.destinationLocation"
        :isLoading="loaders.isLoadingGetMoreDestination"
        v-bind="$props"
      />
    </v-container>
  </v-form>
</template>

<script>
import dayjs from 'dayjs';
import DialogServiceType from '../Dialog/ServiceType.vue';
import DialogVehicle from '../Dialog/Vehicle.vue';
import DialogOrigin from '../Dialog/Origin.vue';
import DialogDestination from '../Dialog/Destination.vue';
import Multipick from './Multipick';
import Multidrop from './Multidrop';
import Goods from './Goods';
import { rulesNotes, dateFormat } from '../../../helper/commonHelpers';

export default {
  name: 'check-order',
  components: {
    DialogServiceType,
    DialogVehicle,
    DialogOrigin,
    DialogDestination,
    Goods,
    Multipick,
    Multidrop,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    allowFetch: {
      type: Object,
      default: () => {},
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    minBackDate: {
      type: String,
      default: '',
    },
    mouDetail: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTooltip: true,
      menu_date: false,
      rules: {
        required: (v, text, isPhone = false) => {
          if (!v) {
            return this.$_strings.messages.validation.REQUIRED(text);
          }
          if (v.length > 255) {
            return 'Maksimal 225 karakter atau kurang';
          }
          return !!v;
        },
        dateRules: [
          (v) => !!v || 'Tanggal harus diisi',
        ],
        time: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Jam'),
          (value) => {
            const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            return pattern.test(value) || 'Format jam salah';
          },
          // () => {
          //   const { pickupDate, pickupTime } = this.form.tools;
          //   const formatDate = dayjs(`${pickupDate} ${pickupTime}`).format();
          //   const date1 = dayjs(formatDate);
          //   const date2 = dayjs();
          //   const x = date1.diff(date2, 'hours', true);
          //   if (x >= 2) {
          //     return true;
          //   }
          //   return 'Minimal 2 jam dari waktu saat ini';
          // },
        ],
        reffShipperMergeShipmentId: [
          (v) => !!v || 'Nomor pesanan harus dipilih',
        ],
        cubic: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.manualOrders.KUBIKASI),
          (value) => {
            if (!Number(value)) {
              return 'KUBIKASI harus lebih dari 0';
            }
            return true;
          },
        ],
        tonase: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.manualOrders.TONASE),
          (value) => {
            if (!Number(value)) {
              return 'TONASE harus lebih dari 0';
            }
            return true;
          },
        ],
        carton: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.manualOrders.KARTON),
          (value) => {
            if (!Number(value)) {
              return 'KARTON harus lebih dari 0';
            }
            return true;
          },
        ],
      },
      isLoadingGetSubCategory: false,
      isValidForm: true,
      subCategoryFilter: {
        page: 0,
        size: 50,
        totalData: 0,
      },
    };
  },
  computed: {
    showCardConfirm() {
      const currentDate = dayjs().format();
      const pickupDate = dayjs(this.form.pickupDate).format();
      return pickupDate < currentDate;
    },
  },
  watch: {
    'form.tools.pickupTime': function setPickupTime(newVal) {
      const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (pattern.test(newVal)) {
        this.setPickupDateFormat();
      }
    },
    'form.tools.pickupDate': function setPickupDate(newVal) {
      this.setPickupDateFormat();
    },
  },
  methods: {
    rulesNotes,
    dayjs,
    dateFormat,
    resetSubCategoryItems() {
      this.subCategoryFilter = {
        page: 0,
        size: 50,
        totalData: 0,
      };
      if (this.form.subCategoryId) this.form.subCategoryId = null;
      this.form.items.itemsSubCategory = [];
    },
    updateSubCategoryItems(items) {
      this.form.items.itemsSubCategory = [...this.form.items.itemsSubCategory, ...items];
    },
    changeCubicOrTonase() {
      if (this.form.tonase && this.form.cubic && !this.form.carton) {
        this.form.carton = 0;
      }
    },
    handlePickupDateChange() {
      this.allowFetch.isAllowFetchServiceType = true;
    },
    setPickupDateFormat() {
      const { pickupDate, pickupTime } = this.form.tools;
      if (pickupDate && pickupTime) {
        const pickupDate2 = dayjs(`${pickupDate}`).format('YYYY-MM-DD');
        const formatDate = dayjs(`${pickupDate2} ${pickupTime}`).format();
        this.form.pickupDate = formatDate;
        if (this.allowFetch.isAllowFetchServiceType) {
          this.$emit('getServiceType');
          this.$emit('resetValueData', 'fullResetForm');
        }
      }
    },
    resetForm() {
      // RESET FORM
      this.$refs.createOrder.reset();

      // REMOVE BORDER AND MESSAGE ERROR SERVICE TYPE
      this.$refs.cardServiceType.$el.classList.remove('v-card-error');
      this.$refs.errorServiceType.innerHTML = '';
      this.$refs.errorServiceType.classList.remove('v-messages', 'error--text');

      // REMOVE BORDER AND MESSAGE ERROR VEHICLE
      if (this.form.serviceType !== 'LCL') {
        this.$refs.cardVehicleType.$el.classList.remove('v-card-error');
        this.$refs.errorVehicle.innerHTML = '';
        this.$refs.errorVehicle.classList.remove('v-messages', 'error--text');
      }

      // REMOVE BORDER AND MESSAGE ERROR ORIGN
      this.$refs.cardLocOrigin.$el.classList.remove('v-card-error');
      this.$refs.errorLocOrigin.innerHTML = '';
      this.$refs.errorLocOrigin.classList.remove('v-messages', 'error--text');

      // REMOVE BORDER AND MESSAGE ERROR DESTINATION
      this.$refs.cardLocDest.$el.classList.remove('v-card-error');
      this.$refs.errorLocDest.innerHTML = '';
      this.$refs.errorLocDest.classList.remove('v-messages', 'error--text');
    },
    validateForm() {
      let validateVehicle = true;
      let validateTypeTarif = true;
      let validateLocOrigin = true;
      let validateLocDest = true;
      if (!this.form.transportTypeId && (this.form.serviceType !== 'LCL')) {
        this.$refs.cardVehicleType.$el.classList.add('v-card-error');
        this.$refs.errorVehicle.innerHTML = 'Kendaraan wajib dipilih';
        this.$refs.errorVehicle.classList.add('v-messages', 'error--text');
        validateVehicle = false;
      }
      if (!this.form.serviceType) {
        this.$refs.cardServiceType.$el.classList.add('v-card-error');
        this.$refs.errorServiceType.innerHTML = 'Jenis tarif wajib diisi';
        this.$refs.errorServiceType.classList.add('v-messages', 'error--text');
        validateTypeTarif = false;
      }
      if (!this.form.originLocationId) {
        this.$refs.cardLocOrigin.$el.classList.add('v-card-error');
        this.$refs.errorLocOrigin.innerHTML = 'Lokasi asal wajib diisi';
        this.$refs.errorLocOrigin.classList.add('v-messages', 'error--text');
        validateLocOrigin = false;
      }
      if (!this.form.destinationLocationId) {
        this.$refs.cardLocDest.$el.classList.add('v-card-error');
        this.$refs.errorLocDest.innerHTML = 'Lokasi tujuan akhir wajib diisi';
        this.$refs.errorLocDest.classList.add('v-messages', 'error--text');
        validateLocDest = false;
      }

      if (this.$refs.createOrder.validate() && validateVehicle && validateTypeTarif && validateLocOrigin && validateLocDest) {
        this.isValidForm = true;
        return true;
      }
      this.isValidForm = false;
      return false;
    },
    getLabelServiceType(type) {
      switch (type) {
        case 'FCL':
          return 'Full Container Load';
        case 'FCL_BACKHAULING':
          return 'Full Container Load + Backhauling';
        case 'FCL+BACKHAULING':
          return 'Full Container Load + Backhauling';
        case 'LCL':
          return 'Less than Container Load';
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-card {
    border: 1px solid rgb(221, 221, 221);
    margin: 0;
    box-shadow: none !important;
  }
  .v-icon, .v-btn__content .v-icon {
    color: #44d1e0;
  }
  .v-icon.mdi-close {
    color: rgb(63, 59, 59);
    position: absolute;
    right: 0;
    top: -2px;
  }
  .v-card-border-blue {
    border-color: rgb(52, 52, 185);
  }
  .v-card-error {
    border:1px solid rgb(255, 0, 0);
  }
</style>
