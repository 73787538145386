<template>
  <div class="wrapper">
    <v-form v-if="orders.length > 0" ref="overviewOrder" lazy-validation @submit.prevent="createShipments">
      <v-container fluid>
        <v-row v-for="(order, orderIndex) in orders" :key="orderIndex">
          <v-col>
            <v-card>
              <v-container fluid>

                <!-- HEADER -->
                <v-row class="align-center rounded grey lighten-5">
                  <v-col cols="8" sm="auto" class="d-flex pr-0">
                    <div class="counter d-inline-block">
                      <span class="font-weight-bold">{{orderIndex + 1}}</span>
                    </div>
                    <div class="mr-2 ml-2">
                      <span class="grey--text">|</span>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <v-icon>mdi-calendar-blank</v-icon>
                      <span class="text-silverChalice caption">{{dateFormat(order.pickupDate)}}</span>
                    </div>
                  </v-col>
                  <v-col cols="4" sm="auto" class="pa-0 caption">
                    <v-icon>mdi-clock-outline</v-icon>
                    <span class="text-silverChalice ">{{dayjs(order.pickupDate).format('HH:mm')}}</span>
                  </v-col>
                  <v-col cols="4" sm="auto" class="d-flex caption align-center">
                    <div class="d-inline-block">
                      <v-icon>mdi-car</v-icon>
                    </div>
                    <div>
                      <span class="text-silverChalice">
                        {{order.serviceType === 'FCL_BACKHAULING' ? 'FCL+BACKHAULING' : order.serviceType}}
                        <span v-if="order.transportTypeId">- {{order.items.itemsVehicle.find((transporter) => transporter.transportTypeId === order.transportTypeId).name }}
                        </span>
                      </span>
                    </div>
                  </v-col>
                  <v-col v-if="order.categoryId" cols="5" sm="auto" class="ma-0 d-flex caption align-center">
                    <div class="d-inline-block">
                      <v-icon>mdi-package-variant-closed</v-icon>
                    </div>
                    <div>
                      <span class="text-silverChalice">
                        {{order.items.itemsCategory.find((category) => category.id === order.categoryId).name}} ({{order.items.itemsSubCategory.find((subCategory) => subCategory.id === order.subCategoryId).name}})
                      </span>
                    </div>
                  </v-col>
                  <v-col sm="auto">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          class="blue lighten-5"
                          rounded
                          small
                        >
                          <div>
                            <v-icon color="primary">mdi-clock-time-nine-outline</v-icon>
                            <span class="caption primary--text">{{ $_strings.manualOrders.ETA }} __/__/____</span>
                          </div>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <h5>{{ $_strings.manualOrders.ETA_NOT_FOUND }}</h5>
                            <span class="caption">karena data belum dikirimkan ke server</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="grey--text d-flex">
                      <div>
                        <v-icon color="light-blue lighten-2" class="mr-2 icon-origin">
                          mdi-map-marker
                        </v-icon>
                      </div>
                      <span>
                        {{order.items.itemsOrigin.find((origin) => origin.value === order.originLocationId).text}}
                      </span>
                    </div>
                    <div class="grey--text d-flex">
                      <div>
                        <v-icon :color="order.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK').length > 0 ? 'green': 'white'" class="mr-2 icon-origin">
                          mdi-map-marker
                        </v-icon>
                      </div>
                      <div class="w-100">
                        <div v-if="order.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK').length > 0">
                          <p v-for="(multipick, index) in order.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK')" :key="index" class="ma-0">Pick {{index + 1}} - {{order.items.itemsMultipick.find((item) => item.value === multipick.locationId).locationName}}</p>
                        </div>
                        <!-- Multidrop-->
                        <v-divider class="mt-2"></v-divider>
                        <div>
                          <v-container>
                            <div v-if="order.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIDROP').length > 0" class="grey--text d-flex">
                              <div>
                                <v-icon color="red accent-4" class="mr-2 icon-origin">
                                  mdi-map-marker
                                </v-icon>
                              </div>
                              <div>
                                <p v-for="(multidrop, index) in order.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIDROP')" :key="index" class="ma-0">Drop {{index + 1}} - {{order.items.itemsMultidrop.find((item) => item.value === multidrop.locationId).locationName}}</p>
                              </div>
                            </div>
                            <div class="grey--text d-flex mt-2">
                              <div>
                                <v-icon color="yellow accent-4" class="mr-2 icon-origin">
                                  mdi-map-marker
                                </v-icon>
                              </div>
                              <div>
                                <p class="ma-0">{{order.items.itemsDestination.find((destination) => destination.value === order.destinationLocationId).text}}</p>
                              </div>
                            </div>
                          </v-container>
                        </div>
                      </div>
                    </div>

                    <!-- BACKHAULING -->
                    <div v-if="order.serviceType === 'FCL_BACKHAULING'">
                      <div class="mt-4 mb-4">
                        <h4 class="grey--text text--darken-2">{{ $_strings.manualOrders.BACKHAULING }}</h4>
                      </div>
                      <div class="grey--text d-flex">
                        <div>
                          <v-icon color="light-blue lighten-2" class="mr-2 icon-origin">
                            mdi-map-marker
                          </v-icon>
                        </div>
                        <span>{{order.items.itemsOriginBackhauling.find((originBackhauling) => originBackhauling.value === order.backhauling.originLocationId).text}}</span>
                      </div>
                      <div class="grey--text d-flex">
                        <div>
                          <v-icon :color="order.backhauling.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK').length > 0 ? 'green': 'white'" class="mr-2 icon-origin">
                            mdi-map-marker
                          </v-icon>
                        </div>
                        <div class="w-100">
                          <div v-if="order.backhauling.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK').length > 0">
                            <p v-for="(multipick, index) in order.backhauling.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK')" :key="index" class="ma-0">Pick {{index + 1}} - {{order.items.itemsMultipickBackhauling.find((item) => item.value === multipick.locationId).locationName}}</p>
                          </div>
                          <!-- BACKHAULING Multidrop -->
                          <v-divider class="mt-2"></v-divider>
                          <div>
                            <v-container>
                              <div v-if="order.backhauling.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIDROP').length > 0" class="grey--text d-flex">
                                <div>
                                  <v-icon color="red accent-4" class="mr-2 icon-origin">
                                    mdi-map-marker
                                  </v-icon>
                                </div>
                                <div>
                                  <p v-for="(multidrop, index) in order.backhauling.shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIDROP')" :key="index" class="ma-0">Drop {{index + 1}} - {{order.items.itemsMultidropBackhauling.find((item) => item.value === multidrop.locationId).locationName}}</p>
                                </div>
                              </div>
                              <div class="grey--text d-flex mt-2">
                                <div>
                                  <v-icon color="yellow accent-4" class="mr-2 icon-origin">
                                    mdi-map-marker
                                  </v-icon>
                                </div>
                                <div>
                                  <p class="ma-0">{{order.items.itemsDestinationBackhauling.find((destination) => destination.value === order.backhauling.destinationLocationId).text}}</p>
                                </div>
                              </div>
                            </v-container>
                          </div>
                        </div>
                      </div>
                    </div>

                  </v-col>
                  <v-col cols="auto" class="d-flex justify-end align-center">
                    <v-btn
                      text
                      class="pt-6 pb-6 pl-0 pr-0 grey lighten-4 mr-2 text-capitalize"
                      @click="editOrder(orderIndex)"
                    >
                      <div class="d-flex flex-column">
                        <v-icon
                          color="light-blue accent-4"
                          class="icon-origin">
                          mdi-square-edit-outline
                        </v-icon>
                        <span class="grey--text ml-1 caption">{{ $_strings.manualOrders.LABEL.EDIT }}</span>
                      </div>
                    </v-btn>
                    <div class="d-flex mr-2 flex-column grey lighten-4 rounded">
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="400"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                            class="pt-6 pb-6 pl-0 pr-0 text-capitalize"
                          >
                            <div class="d-flex flex-column">
                              <v-icon
                                color="red accent-4"
                                class="icon-origin">
                                mdi-delete
                              </v-icon>
                              <span class="grey--text caption">{{ $_strings.manualOrders.LABEL.DELETE }}</span>
                            </div>
                          </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-card-text>
                              <div class="pt-2">{{ $_strings.manualOrders.CONFIRM.DELETE }}</div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                text
                                @click="dialog.value = false"
                              >{{ $_strings.common.NO}}</v-btn>
                              <v-btn
                                text
                                @click="dialog.value = false, deleteOrder(orderIndex)"
                              >{{ $_strings.common.YES}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="mt-5 justify-end"
        >
          <v-col cols="auto">
            <v-btn
              depressed
              color="warning"
              min-width="200"
              @click="addNewOrder"
            >
              {{ $_strings.manualOrders.ADD_ORDER }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              depressed
              color="primary"
              min-width="200"
              type="submit"
            >
              {{ $_strings.manualOrders.SEND_ORDER }} ({{orders.length}})
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-row v-else align="center">
      <v-col style="height: 400px" class="text-center pa-10 mt-10">
        <v-icon large color="primary">
          mdi-shopping-search
        </v-icon>
        <h2> {{ $_strings.manualOrders.EMPTY_ORDER }} </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '../../../helper/commonHelpers';

export default {
  name: 'overview-order',
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogDeleteOrder: false,
    };
  },
  methods: {
    dayjs,
    dateFormat,
    createShipments() {
      this.$emit('createShipments');
    },
    addNewOrder() {
      this.$emit('setAddNewOrder');
    },
    editOrder(index) {
      this.$emit('editOrder', index);
    },
    deleteOrder(index) {
      this.dialogDeleteOrder = false;
      this.orders.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border: 1px solid #aaa;
    margin: 0;
  }
  .counter {
    background: #efefef;
    text-align: center;
    border-radius: 50%;
    height: 25px;
    width: 35px;
  }
</style>
